import React from 'react';

const Industry = () => {
  const industries = [
    { name: 'Healthcare', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c559a4fdeddd88a6f2b786105bdcdaad35ded89adc9fbcad13c4c6af181da64e?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&' },
    { name: 'Retail', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/6b601f94bb12f5c571733d3fb0ec1c72607b26334b85fe59844e62c5bf05f1db?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&' },
    { name: 'Financial Services', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/a470264cbcb970fc36ed4a398503e7b57336421e3fb74a26728ced2bb258e86f?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&' },
    { name: 'Telecom', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/3f1b16ed6fe793a3362ac6921d89bd62b2f6bf7afa4203e9affed7152633307b?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&' },
    { name: 'Travel and Hospitality', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/5e1d11062983ed53fbc62949a439508461e4425231f57d9910616e7d4f01bed0?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&' },
    { name: 'Real Estate', image: 'https://cdn.builder.io/api/v1/image/assets/TEMP/bcf7233b5c0ddfcec858677f054bc16850dc650f3c5ad027afc1f3be0c65c366?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&' },
  ];

  return (
    <div className="flex flex-col px-5 text-black">
      <h2 className="mt-1.5 w-full text-6xl font-semibold tracking-tighter leading-[60px] max-md:max-w-full max-md:text-4xl max-md:leading-10 text-left ml-4">
        Applications across the industry
      </h2>
      <div className="flex gap-5 mt-16 text-xl font-medium tracking-tighter leading-5 text-center max-md:flex-wrap max-md:mt-10">
        {industries.map((industry, index) => (
          <div key={index} className="flex flex-col flex-1 px-7 py-7 bg-indigo-200 rounded-xl max-md:px-5">
            <img
              loading="lazy"
              src={industry.image}
              className="self-center aspect-square w-[69px]"
              alt={industry.name}
            />
            <div className="mt-4 tracking-tighter">{industry.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Industry;
