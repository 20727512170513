import * as React from "react";
import AssistantComponentDesktop from './assistantComponentDesktop'; // Import the component
import Industry from "./industry";

const SocialIcon = ({ src, alt }) => (
  <img loading="lazy" src={src} className="shrink-0 w-6 aspect-square" alt={alt} />
);

function MyComponent() {
  return (
    <div className="flex flex-col items-center bg-white">
      <div className="self-stretch w-full min-h-[0px] max-md:max-w-full" />
      <header className="flex justify-between items-center self-stretch px-16 py-2.5 w-full font-medium bg-gray-200 max-md:px-5 max-md:max-w-full max-sm:pt-2 max-sm:pb-1 max-sm:-mr-1 max-sm:-mb-1 max-sm:h-auto max-sm:grow-0 sticky top-0 z-50">
        <h1 className="text-2xl text-black max-sm:text-sm ml-2">
          ANTHROPOD
        </h1>
        <div className="flex gap-3 w-full max-w-[1000px] max-md:flex-wrap max-md:max-w-full"></div>
        <a
          href="https://calendly.com/anthropod-info/30min"
          className="box-border relative shrink-0 px-4 py-2 ml-auto mr-2 text-center rounded appearance-none cursor-pointer text-blue-700 border border-blue-700 max-sm:px-2 max-sm:py-1 max-sm:mr-2 max-sm:ml-auto max-sm:text-xs"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book demo
        </a>
      </header>

      <main>
        <section className="flex flex-col items-center justify-center px-1 mt-12 max-w-full text-9xl font-semibold tracking-tighter text-center text-black leading-[113px] w-[857px] max-md:mt-10 max-md:text-4xl max-md:leading-10">
          <h2 className="mr-0 ml-0 tracking-tighter max-md:max-w-full max-md:text-4xl max-md:leading-10 max-sm:mx-auto max-sm:tracking-tighter whitespace-nowrap">
            An Assistant Every
          </h2>
          <span className="tracking-tighter text-indigo-600 max-sm:mt-0.5 max-sm:tracking-tighter">
            Business
          </span>
          <div className="box-border relative shrink-0 -mt-px h-auto tracking-tighter max-sm:mt-1 max-sm:tracking-tighter">
            Head Needs.
          </div>
          <div className="box-border inline-block relative flex-col shrink-0" />
        </section>
       

        <p className="mt-16 text-3xl leading-9 text-center text-gray-600 w-[833px] max-md:mt-10 max-md:max-w-full max-sm:px-1.5 max-sm:mx-2 max-sm:text-lg max-sm:leading-6 max-sm:w-[807px]">
          AI-Enabled Call Analytics to Maximize Sales ROI and Transform Your Customer Support Centers
        </p>

        <a
          href="https://calendly.com/anthropod-info/30min"
          className="box-border relative block px-4 py-3 mx-auto mt-8 text-lg leading-8 text-center bg-blue-700 rounded appearance-none cursor-pointer text-white w-[150px] h-[55px] max-sm:px-3 max-sm:py-2 max-sm:mx-auto max-sm:text-base max-sm:leading-8 max-sm:bg-blue-700 max-sm:h-[48px] max-sm:w-[120px]"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginTop: "2rem" }} // Adjust margin-top as needed
        >
          Book a demo
        </a>

        <div className="box-border flex relative flex-col shrink-0 mt-6 -ml-px w-full bg-white border-0 border-solid border-[black] max-w-[1257px] min-h-[500px] min-w-[20px] rounded-[35px] max-sm:mt-4 max-sm:max-w-[923px]">
          <div className="relative">
            <iframe
              className="box-border flex object-contain relative flex-col shrink-0 mt-12 -ml-px bg-white border-0 border-solid border-[black] max-w-[1257px] min-h-[500px] min-w-[20px] rounded-[35px] size-full max-sm:mt-8 max-sm:max-w-[923px]"
              width="560"
              height="560"
              src="https://www.youtube.com/embed/JXwiMBKiPmE?rel=0&modestbranding=1&showinfo=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
             
            ></iframe>
            <div className="pt-44 w-full text-xs pointer-events-none" />
          </div>
        </div>
      </main>

      {/* Insert AssistantComponentDesktop with minimal padding */}
      <div style={{ padding: '0', marginTop:'0rem' }}>
        <AssistantComponentDesktop />
      </div>
      <div style={{ padding: '0', marginTop:'4rem' }}>
        <Industry />
      </div>

    
      <footer className="flex justify-center items-center self-stretch px-16 py-14 w-full bg-black mt-[632px] max-md:px-5 max-md:mt-10 max-md:max-w-full max-sm:mt-80">
        <div className="flex flex-col w-full max-w-[1170px] max-md:max-w-full">
          <div className="flex flex-col px-10 max-md:px-5 max-md:max-w-full">
            <h3 className="flex-auto gap-5 items-start px-0.5 w-full text-2xl tracking-tighter text-white font-[450] max-md:flex-wrap max-md:max-w-full">
              Contact Details
            </h3>
            <address className="flex gap-5 px-1 mt-3.5 w-full text-sm tracking-tight text-gray-500 max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto">
                <p>Email - info@anthropod.in</p>
                <p>Beehive Premium HSR Campus, Bengaluru, Karnataka 560068</p>
              </div>
            </address>

            <div className="flex gap-5 self-start mt-5">
              <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/221367730b7144159225d892bb7699e5c46dd5eacb6e246dd139a7dc0c33a084?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&" alt="Social media icon" />
              <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e4c1f0ccb3b9a5d13a18a37cfba335a970ddd6c019a76d8655f5e83f6dcfe14?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&" alt="Social media icon" />
              <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f2e3fd8227145dba49ceb21da8e0d084c2692d077d9b7e4c02357a9c526594df?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&" alt="Social media icon" />
              <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/1e522a58649ecb53ff1bf58a07c5a1d58df5bd22e2e263721e3b609a8fbd0289?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&" alt="Social media icon" />
            </div>

            <p className="text-sm text-gray-500 mt-5">
              © 2022 Anthropod. All rights reserved. Terms and conditions | Privacy policy
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default MyComponent;
