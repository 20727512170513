import React, { useState } from 'react';

const Button = ({ children, className, onClick }) => (
  <button
    className={`box-border relative shrink-0 px-3 py-1 ml-1 text-center rounded-md border border-black border-solid appearance-none cursor-pointer ${className}`}
    onClick={onClick}
  >
    {children}
  </button>
);

const FeatureList = ({ features }) => (
  <div className="mt-5 ml-8 text-base tracking-tight leading-6 max-md:max-w-full max-sm:mr-1 max-sm:ml-px">
    {features.map((feature, index) => (
      <React.Fragment key={index}>
        <span className="text-lg tracking-tight text-green-500">✓</span>{" "}
        <span className="text-base tracking-tight">{feature}</span>
        <br />
      </React.Fragment>
    ))}
  </div>
);

const AcquisitionRetentionAssistant = () => {
  const [currentContent, setCurrentContent] = useState('Sales');

  const contentData = {
    Sales: {
      title: "Maximize revenue from your existing funnels",
      buttonText: "Sales Solutions",
      features: [
        "Real Time Monitoring of Sales Funnel & Call Audits",
        "Sales Pitch Optimization & Personalization",
        "Customer Sentiment Analysis",
        "AI answers for better query handling",
        "Agent Insights for Quality Audit & Training",
        "Standardize communications",
        "Enrich Customer Attributes for better cross sells & retargeting",
        "Automated Follow-ups for better conversion"
      ],
      imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/0a166b611fb05ed52471182145cb24471d6c47a2f3f4e70ce6f4ef7353d1c4d7?apiKey=d2230bd6e0cb41799b41f1be1f5dd874&"
    },
    Marketing: {
      title: "Boost your marketing efficiency",
      buttonText: "Marketing Solutions",
      features: [
        "Targeted Campaign Analysis",
        "Marketing Spend Optimization",
        "Customer Segmentation",
        "Campaign Performance Tracking",
        "AI-Powered Recommendations",
        "Lead Scoring & Nurturing",
        "Attribution Modeling",
        "Conversion Rate Optimization"
      ],
      imageUrl: "https://cdn.builder.io/api/v1/image/assets%2Fd2230bd6e0cb41799b41f1be1f5dd874%2F2beed509d2284928809c3c1799f45d08"
    },
    CustomerSupport: {
      title: "Enhance your customer support",
      buttonText: "Customer Support",
      features: [
        "24/7 AI-Powered Support",
        "Multi-Channel Integration",
        "Sentiment Analysis",
        "Automated Response Suggestions",
        "Customer Feedback Analysis",
        "Support Ticket Prioritization",
        "Knowledge Base Management",
        "Performance Metrics Tracking"
      ],
      imageUrl: "https://cdn.builder.io/api/v1/image/assets%2Fd2230bd6e0cb41799b41f1be1f5dd874%2Fade87728595e43008b714982b2021c0a"
    },
    Product: {
      title: "Optimize your product management",
      buttonText: "Product Solutions",
      features: [
        "Product Lifecycle Management",
        "Feature Prioritization",
        "Customer Feedback Integration",
        "Market Trend Analysis",
        "Development Cycle Optimization",
        "User Experience Insights",
        "A/B Testing",
        "Product Performance Metrics"
      ],
      imageUrl: "https://cdn.builder.io/api/v1/image/assets%2Fd2230bd6e0cb41799b41f1be1f5dd874%2Fdb11fb22889a4cc2825505c5733dd4b7"
    }
  };

  const getButtonClass = (buttonKey) => {
    if (currentContent === buttonKey) {
      return 'bg-indigo-600 text-white';
    }
    return 'bg-neutral-400 text-white';
  };

  const handleButtonClick = (buttonKey) => {
    setCurrentContent(buttonKey);
  };

  const currentData = contentData[currentContent];

  return (
    <div className="flex flex-col px-5">
      <div className="pl-8 mt-7 w-full text-5xl font-semibold tracking-tighter text-black leading-[60px] max-md:max-w-full max-md:text-4xl max-md:leading-10 max-sm:pl-px max-sm:mt-3 max-sm:-ml-px">
        An Assistant That Does Everything From Acquisition To Retention
      </div>
      <div className="flex gap-2 mt-5 text-lg font-medium tracking-tighter leading-6 text-center text-black max-md:flex-wrap max-md:pr-5 max-md:mt-5">
        {Object.keys(contentData).map((buttonKey, index) => (
          <Button
            key={index}
            className={`px-2 py-1 ${getButtonClass(buttonKey)}`}
            onClick={() => handleButtonClick(buttonKey)}
          >
            <span className="text-sm">{contentData[buttonKey].buttonText}</span>
          </Button>
        ))}
      </div>
      <div className="px-px mt-10 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col max-md:flex-col max-md:gap-0">
          <img
            loading="lazy"
            src={currentData.imageUrl}
            alt={`${currentContent} visualization`}
            className="grow -mt-1 w-full aspect-[1.2] max-md:mt-8 max-md:max-w-full max-sm:mt-3"
            style={{ maxWidth: '100%' }}
          />
          <div className="flex flex-col w-full max-md:w-full">
            <div className="flex flex-col grow pb-0.5 mt-auto mr-auto max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col text-black max-md:max-w-full">
                <div className="ml-12 text-3xl font-medium tracking-tighter leading-8 max-md:max-w-full max-sm:-mt-1 max-sm:ml-2.5">
                  {currentData.title}
                </div>
                <FeatureList features={currentData.features} />
                <Button
                  className="mt-5 ml-10 bg-[black] text-[white] max-sm:mx-auto"
                  style={{ padding: "8px 12px" }}
                  onClick={() => window.open("https://calendly.com/anthropod-info/30min", "_blank", "noopener noreferrer")}
                >
                  Book a Demo
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcquisitionRetentionAssistant;
