import React from 'react';
import MyComponentDesktop from './MyComponentDesktop';
import MyComponentMobile from './MyComponentMobile';

function App() {
  // Use state or a library like react-responsive to determine device width
  const isMobile = window.innerWidth <= 768; // Adjust the width as per your design breakpoints

  return (
    <div className="App">
      {isMobile ? <MyComponentMobile /> : <MyComponentDesktop />}
    </div>
  );
}

export default App;
