import React from "react";
import AssistantComponentMobile from './assistantComponentMobile'; // Import the component
import Industry from "./industry";



const NavLink = ({ children }) => (
  <div className="tracking-tighter text-xs">{children}</div>
);

function MyComponentMobile() {
  return (
    <div className="flex flex-col items-center bg-white">
      <header className="flex justify-between items-center px-4 py-2 w-full font-medium bg-gray-100 sticky top-0 z-10">
        <h1 className="text-xl text-black">ANTHROPOD</h1>
        <a
          href="https://calendly.com/anthropod-info/30min"
          className="px-3 py-2 text-xs text-center bg-transparent border border-blue-700 rounded text-blue-700 hover:bg-blue-700 hover:text-white transition-colors duration-300"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book demo
        </a>
      </header>

      <main className="px-4">
        {/* Content section */}
        <section className="flex flex-col justify-center mt-5 text-4xl font-semibold tracking-tighter text-center text-black leading-tight">
          <h2 className="tracking-tighter">
            An Assistant Every <br />
          </h2>
          <span className="tracking-tighter text-indigo-600">Business</span>
          <div className="tracking-tighter">Head Needs.</div>
         
        </section> 
        
        <p className="mt-8 text-base leading-6 text-center text-black">
          AI-Enabled Call Analytics to Maximize Sales ROI and Transform Your
          Customer Support Centers
        </p>
        <a
          href="https://calendly.com/anthropod-info/30min"
          className="block px-2 py-1 mx-auto mt-8 text-sm leading-7 text-center bg-blue-700 rounded text-white w-28"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a demo
        </a>

        <div className="mt-8 w-full rounded-2xl overflow-hidden">
          <iframe
            className="w-full"
            width="100%"
            height="220"
            src="https://www.youtube.com/embed/JXwiMBKiPmE?rel=0&modestbranding=1&showinfo=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            
            allowFullScreen
          ></iframe>
        </div>
      </main>

      {/* AssistantComponentMobile with minimal padding */}
      <div style={{ padding: '0', marginTop:'0rem' }}>
        <AssistantComponentMobile />
      </div>

      {/* Industry component */}
      <div style={{ padding: '0', marginTop:'0rem' }}>
        <Industry />
      </div>

      {/* Footer */}
      <footer className="w-full bg-black mt-16 px-4 py-8">
        <div className="max-w-sm mx-auto">
          <h3 className="text-xl tracking-tighter text-gray-600 font-medium">
            Contact Details
          </h3>
          <address className="mt-2 text-xs tracking-tight text-gray-400">
            <p>Email - info@anthropod.in</p>
            
            <p>Beehive Premium HSR Campus, Bengaluru, Karnataka 560068</p>
          </address>

          <hr className="my-6 border-gray-500" />

          <div className="flex flex-col items-center gap-4">
            <div className="text-2xl text-gray-600 font-medium">
              ANTHROPOD
            </div>
            <nav className="flex flex-wrap justify-center gap-4 text-xs text-gray-800">
              <NavLink>Terms of use</NavLink>
              <NavLink>Privacy policy</NavLink>
            </nav>
            <div className="text-xs text-gray-800">Copyright@2024 Anthropod</div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default MyComponentMobile;
